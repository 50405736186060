<template>
  <div class="whiteList">
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="80px">
          <el-row
            :gutter="10"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row"
          >
            <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="operation:" class="mb5">
                <el-select
                  v-model="filterForm.operation"
                  filterable
                  collapse-tags
                  placeholder="Please select"
                  class="w100"
                  clearable
                >
                  <el-option v-for="item in bidAccountList" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="BD:" class="mb5">
                <group-select
                  v-model="filterForm.businessList"
                  :data-source="busineies"
                  :multiple="true"
                  :loading="sourceLoading"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select BD"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="source:" class="mb5">
                <group-select
                  v-model="filterForm.sourceIdList"
                  :data-source="sourcesList"
                  :multiple="true"
                  :loading="sourceLoading"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select Source"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="prod:" class="mb5">
                <el-select v-model="filterForm.prod" clearable filterable allow-create>
                  <el-option
                    v-for="item in prodList"
                    :key="item"
                    :value="item"
                    :label="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="appName:" class="mb5">
                <el-select v-model="filterForm.appNames" clearable multiple allow-create filterable>
                  <el-option
                    v-for="item in appList"
                    :key="item"
                    :value="item"
                    :label="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="offerType:" class="mb5">
                <el-select v-model="filterForm.offerType" clearable>
                  <el-option
                    v-for="item in offerTypeList"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="status" class="mb5">
                <el-select v-model="filterForm.status" multiple clearable>
                  <el-option
                    v-for="item in statusList"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="period:" class="mb5">
                <el-date-picker
                  v-model="filterForm.dateRange"
                  clearable
                  type="monthrange"
                  value-format="yyyy-MM"
                  range-separator="to"
                  start-placeholder="start"
                  end-placeholder="end"
                  @clearable-change="isShowRangeTime()"
                  @change="isShowRangeTime()"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="BD Group:" class="mb5">
                <el-select v-model="filterForm.businessType" clearable filterable>
                  <el-option
                    v-for="item in businessTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="AM" class="mb5">
                <group-select
                  v-model="filterForm.accountManage"
                  :data-source="accounts"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select AM"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="AM Group" class="mb5">
                <el-select v-model="filterForm.accountType" clearable>
                  <el-option
                    v-for="item in businessTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="confirm:" class="mb5">
                <el-select v-model="filterForm.confirmNum" clearable style="width: auto">
                  <el-option :key="0" :value="0" label="0"></el-option>
                  <el-option :key="1" :value="1" label="不等于0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            :gutter="10"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row"
          >
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" align="left" class="mr10">
              <div>
                <el-carousel height="150px" trigger="click" arrow="always" :interval="10000">
                  <template v-for="(value, key) in sumInfoMap">
                    <el-carousel-item v-if="key === 'total'" :key="key">
                      <div class="block">
                        <span class="demonstration ml10 font-16 cor337ab7">{{ key }}</span>
                        <el-form-item label-width="0" class="mb5">
                          <span
                            :xs="4"
                            :sm="3"
                            :md="3"
                            :lg="4"
                            :xl="3"
                            class="ml10 font-16 cor337ab7"
                            >mmp:[{{ value.mmpRevenue | numberToCurrency }}]</span
                          >
                          <span
                            :xs="4"
                            :sm="3"
                            :md="3"
                            :lg="3"
                            :xl="3"
                            class="ml10 font-16 cor337ab7"
                            >confirm: [{{ value.confirmNum | numberToCurrency }}]</span
                          >
                          <span class="progress-label ml10 font-16 cor337ab7"
                            >revenue(%):
                            <el-progress
                              :percentage="value.confirmRatio"
                              status="success"
                              :text-inside="true"
                              :stroke-width="20"
                            >
                            </el-progress
                          ></span>
                        </el-form-item>
                        <el-form-item label-width="0" class="mb5">
                          <span
                            :xs="4"
                            :sm="3"
                            :md="3"
                            :lg="4"
                            :xl="3"
                            class="ml10 font-16 cor337ab7"
                            >mmpCount:[{{ value.mmpCount | numberToCurrency }}]</span
                          >
                          <span
                            :xs="4"
                            :sm="3"
                            :md="3"
                            :lg="3"
                            :xl="3"
                            class="ml10 font-16 cor337ab7"
                            >confirmCount: [{{ value.confirmCount | numberToCurrency }}]</span
                          >
                          <span class="progress-label ml10 font-16 cor337ab7"
                            >count( %):
                            <el-progress
                              :percentage="value.countRatio"
                              status="success"
                              :text-inside="true"
                              :stroke-width="20"
                            >
                            </el-progress
                          ></span>
                        </el-form-item>
                      </div>
                    </el-carousel-item>
                  </template>
                  <template v-for="(value, key) in sumInfoMap">
                    <el-carousel-item v-if="key !== 'total'" :key="key">
                      <div class="block">
                        <span class="demonstration ml10 font-16 cor337ab7">{{ key }}</span>
                        <el-form-item label-width="0" class="mb5">
                          <span
                            :xs="4"
                            :sm="3"
                            :md="3"
                            :lg="4"
                            :xl="3"
                            class="ml10 font-16 cor337ab7"
                            >mmp:[{{ value.mmpRevenue | numberToCurrency }}]</span
                          >
                          <span
                            :xs="4"
                            :sm="3"
                            :md="3"
                            :lg="3"
                            :xl="3"
                            class="ml10 font-16 cor337ab7"
                            >confirm: [{{ value.confirmNum | numberToCurrency }}]</span
                          >
                          <span class="progress-label ml10 font-16 cor337ab7"
                            >revenue(%):
                            <el-progress
                              :percentage="value.confirmRatio"
                              status="success"
                              :text-inside="true"
                              :stroke-width="20"
                            >
                            </el-progress
                          ></span>
                        </el-form-item>
                        <el-form-item label-width="0" class="mb5">
                          <span
                            :xs="4"
                            :sm="3"
                            :md="3"
                            :lg="4"
                            :xl="3"
                            class="ml10 font-16 cor337ab7"
                            >mmpCount:[{{ value.mmpCount | numberToCurrency }}]</span
                          >
                          <span
                            :xs="4"
                            :sm="3"
                            :md="3"
                            :lg="3"
                            :xl="3"
                            class="ml10 font-16 cor337ab7"
                            >confirmCount: [{{ value.confirmCount | numberToCurrency }}]</span
                          >
                          <span class="progress-label ml10 font-16 cor337ab7"
                            >count( %):
                            <el-progress
                              :percentage="value.countRatio"
                              status="success"
                              :text-inside="true"
                              :stroke-width="20"
                            >
                            </el-progress
                          ></span>
                        </el-form-item>
                      </div>
                    </el-carousel-item>
                  </template>
                </el-carousel>
              </div>
            </el-col>
            <el-col :xs="11" :sm="11" :md="11" :lg="11" :xl="11" align="right" class="mr10">
              <el-form-item label-width="0" class="mb5">
                <el-button
                  type="primary"
                  :loading="loading.list"
                  @click="
                    getList(1);
                    getProgress();
                  "
                  >Search</el-button
                >
                <el-button type="info" :loading="loading.list" @click="downloadFie"
                  >download</el-button
                >
                <el-button type="warning" @click="combineToAdv" v-if="flag"
                  >combine await</el-button
                >
                <el-button type="danger" @click="confirmBatch" :loading="loading.submitBtn"
                  >confirm</el-button
                >
                <el-button type="danger" @click="pendingBatch" :loading="loading.submitBtn"
                  >pending</el-button
                >
                <el-button
                  type="danger"
                  @click="confirmADV"
                  v-if="!flag"
                  :loading="loading.submitBtn"
                  >confirm ADV</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card> </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width: 100%"
          stripe
          border
          :data="list"
          tooltip-effect="dark"
          aria-setsize="mini"
          size="mini"
          class="mb10"
          height="800"
          :row-style="{ height: '50px' }"
          @sort-change="sortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column label="Operation" prop="operation" align="center"></el-table-column>
          <el-table-column label="BD" prop="business" align="center"></el-table-column>
          <el-table-column
            label="SourceName"
            width="80"
            prop="sourceName"
            align="center"
          ></el-table-column>
          <el-table-column
            label="BD Group"
            prop="businessType"
            align="center"
            :key="40"
          ></el-table-column>
          <el-table-column label="AM" prop="accountManage" align="center"></el-table-column>
          <el-table-column label="AM Group" prop="accountType" align="center"></el-table-column>
          <el-table-column
            label="Prod"
            prop="prod"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="AppName" prop="appName" align="center"></el-table-column>
          <el-table-column label="OfferType" prop="offerType" align="center"></el-table-column>
          <el-table-column label="pid" prop="pid" align="center"></el-table-column>
          <el-table-column
            label="MMP Revenue"
            width="80"
            prop="mmpRevenue"
            align="center"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{ scope.row.mmpRevenue | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column
            width="100"
            label="Confirm Revenue"
            prop="confirmNum"
            align="center"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{ scope.row.confirmNum | numberToCurrency }}
            </template></el-table-column
          >
          <el-table-column label="Diffrence" prop="diffrence" align="center" sortable="custom"
            ><template slot-scope="scope">
              {{ scope.row.diffrence | numberToCurrency }}
            </template></el-table-column
          >
          <el-table-column label="Dedution" prop="dedution" align="center">
            <template slot-scope="scope">
              {{ formatDedution(scope.row.dedution) | numberToCurrency }}%
            </template>
          </el-table-column>
          <el-table-column label="Payout" prop="payout" align="center">
            <template slot-scope="scope">
              <span @click="detailAutoMixClick(scope.row)" v-if="scope.row.id" class="cor337ab7">{{
                scope.row.payout | numberToCurrency
              }}</span>
              <span v-else>{{ scope.row.payout | numberToCurrency }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Profit Rate" prop="profitRate" align="center">
            <template slot-scope="scope">
              <template v-if="scope.row.profitRate">
                <span v-if="scope.row.profitRate < 0" style="color: red"
                  >{{ scope.row.profitRate }}%
                </span>
                <span v-else>{{ scope.row.profitRate }}%</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            label="Notes"
            prop="notes"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="ConfirmStatus" width="80" prop="confirmStatus" align="center">
            <template slot-scope="scope">
              {{ formatValue(scope.row.confirmStatus) }}
              <el-tooltip
                v-if="scope.row.confirmStatus"
                class="item"
                effect="dark"
                placement="top-start"
              >
                <div v-if="scope.row.confirmStatus == 0" slot="content"
                  >Just created, unprocessed
                </div>
                <div v-if="scope.row.confirmStatus == 1" slot="content"
                  >BD has been confirmed and has not yet entered the invoicing process
                </div>
                <div v-if="scope.row.confirmStatus == 2" slot="content"
                  >BD has been confirmed and entered the invoicing process
                </div>
                <div v-if="scope.row.confirmStatus == 3" slot="content"
                  >BD unconfirmed, disputed data
                </div>
                <div v-if="scope.row.confirmStatus == 4" slot="content"
                  >Small amount confirmed but not invoiced this month, needs to be invoiced together
                  with next month's data
                </div>
                <div v-if="scope.row.confirmStatus == 5" slot="content"
                  >Submitted applications that have not yet completed the process
                </div>
                <i class="el-icon-warning-outline" />
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Period" prop="financeCycle" align="center"></el-table-column>
          <el-table-column label="OperationTime" prop="updateTime" align="center">
            <template slot-scope="scope">
              {{ formatOperationTime(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column label="Action" align="center" fixed="right" width="120">
            <template slot-scope="scope">
              <el-dropdown size="mini" split-button v-if="scope.row.id" type="primary">
                Option
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    ><el-button
                      class="mb5"
                      style="width: 100%"
                      type="primary"
                      size="mini"
                      @click="openDialog(scope.row)"
                      :disabled="isEdit(scope.row)"
                      title="edit"
                      >edit</el-button
                    ></el-dropdown-item
                  >
                  <el-dropdown-item
                    ><el-button
                      class="mb5"
                      size="mini"
                      style="width: 100%"
                      :type="handleConfirmStatus(scope.row)"
                      :disabled="idDisableConfirm(scope.row.confirmStatus)"
                      @click="confirmBD(scope.row)"
                      title="edit to confirm"
                      >confirm</el-button
                    ></el-dropdown-item
                  >
                  <el-dropdown-item
                    ><el-button
                      class="mb5"
                      size="mini"
                      style="width: 100%"
                      type="danger"
                      :disabled="idDisableConfirm(scope.row.confirmStatus)"
                      @click="update2Pending(scope.row)"
                      title="edit to pending"
                      >pending</el-button
                    ></el-dropdown-item
                  >
                  <el-dropdown-item>
                    <el-button
                      class="mb5"
                      size="mini"
                      style="width: 100%"
                      type="info"
                      :disabled="scope.row.confirmStatus === '2'"
                      @click="update2Await(scope.row)"
                      title="edit to await"
                      >await</el-button
                    ></el-dropdown-item
                  >
                  <el-dropdown-item>
                    <el-button
                      class="mb5"
                      size="mini"
                      style="width: 100%"
                      type="success"
                      @click="toApplyApproval(scope.row)"
                      title="apply approval"
                      v-if="isShowApproval(scope.row)"
                      >approval</el-button
                    ></el-dropdown-item
                  >
                  <!--                  <el-dropdown-item>
                    <el-button
                      class="mb5"
                      size="mini"
                      style="width: 100%"
                      type="danger"
                      @click="toStatusApproval(scope.row)"
                      title="update status"
                      :disabled="scope.row.confirmStatus === '2' || scope.row.confirmStatus === '5'"
                      >update status</el-button
                    ></el-dropdown-item
                  >-->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>

        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
    <!--  编辑模态框-->
    <el-dialog :title="dialog.title" :visible.sync="dialog.visible" width="40%">
      <el-form :model="editData" size="small" ref="editForm" label-width="auto" :rules="rules">
        <el-form-item label="appName：" prop="appName">
          <el-input v-model="editData.appName" placeholder="Please enter appName"></el-input>
        </el-form-item>
        <el-form-item label="MMP Revenue：" prop="mmpRevenue">
          <el-input disabled v-model="editData.mmpRevenue" placeholder="MMP Revenue"></el-input>
        </el-form-item>
        <el-form-item label="confirm Revenue：" prop="confirmNum">
          <el-input
            v-model="editData.confirmNum"
            placeholder="Please enter confirm Revenue"
          ></el-input>
        </el-form-item>
        <el-form-item label="notes：" prop="notes">
          <el-input v-model="editData.notes" placeholder="Please enter notes"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog.visible = false">取 消</el-button>
        <el-button type="primary" @click="editForm('editForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!--  提交审批 -->
    <el-dialog
      title="Apply An Approval"
      :visible.sync="applyApproval.visible"
      fullscreen
      :before-close="applyBeforeClose"
      height="100%"
      top="3vh"
    >
      <el-row>
        <el-form
          :model="applyApprovalForm"
          size="mini"
          ref="applyApprovalForm"
          label-width="5rem"
          :rules="applyRules"
        >
          <el-col :xs="24" :lg="12" :md="12" :sm="24" align="right">
            <el-form-item class="approval-input-width" label="appName" prop="appName">
              <el-input v-model="applyApprovalForm.appName" placeholder="appName"></el-input>
            </el-form-item>
            <el-form-item class="approval-input-width" label="Offer" prop="offer">
              <el-input v-model="applyApprovalForm.offer" placeholder="OfferID"></el-input>
            </el-form-item>
            <el-form-item class="approval-input-width" label="Reason" prop="reason">
              <el-select
                v-model="applyApprovalForm.reason"
                @change="handleApplyReasonChange"
                style="width: 100%"
                placeholder=""
              >
                <el-option
                  v-for="item in applyApproval.reasonOptions"
                  :label="item.label"
                  :value="item.value"
                  :key="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="approval-input-width" label="Total Amount" prop="totalAmount">
              <el-input
                v-model="applyApprovalForm.totalAmount"
                @blur="applyAmountChange"
                placeholder="0.00"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="approval-input-width"
              label="Deduction Amount"
              prop="deductionAmount"
            >
              <el-input
                v-model="applyApprovalForm.deductionAmount"
                @blur="applyAmountChange"
                placeholder="0.00"
              ></el-input>
            </el-form-item>
            <el-form-item class="approval-input-width" label="Cover Amount" prop="coverAmount">
              <el-input
                v-model="applyApprovalForm.coverAmount"
                @blur="applyAmountChange"
                placeholder="0.00"
              ></el-input>
            </el-form-item>
            <el-form-item class="approval-input-width" label="Payable Amount" prop="payableAmount">
              <el-input
                v-model="applyApprovalForm.payableAmount"
                disabled
                placeholder="0.00"
              ></el-input>
            </el-form-item>
            <el-form-item class="approval-input-width" label="Deduction %" prop="deduction">
              <el-input
                v-model="applyApprovalForm.deduction"
                disabled
                placeholder="0.00"
              ></el-input>
            </el-form-item>
            <el-form-item class="approval-input-width" label="Cover %" prop="cover">
              <el-input v-model="applyApprovalForm.cover" disabled placeholder="0.00"></el-input>
            </el-form-item>
            <el-form-item class="approval-input-width" label="Note 1" prop="note1">
              <el-input
                :autosize="{ minRows: 3, maxRows: 10 }"
                maxlength="255"
                show-word-limit
                type="textarea"
                v-model="applyApprovalForm.note1"
                placeholder=""
              ></el-input>
            </el-form-item>
            <el-form-item class="approval-input-width" label="Note 2" prop="note2">
              <el-input
                :autosize="{ minRows: 3, maxRows: 10 }"
                maxlength="255"
                show-word-limit
                type="textarea"
                v-model="applyApprovalForm.note2"
                placeholder=""
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :lg="12" :md="12" :sm="24" align="left"><br /><br /><br /></el-col>
          <el-col :xs="24" :lg="12" :md="12" :sm="24" align="left"><br /><br /></el-col>
          <el-col :xs="24" :lg="12" :md="12" :sm="24" align="left">
            <!-- reason pa -->
            <div v-show="applyApprovalForm.reason === 'pa'">
              <el-form-item
                class="approval-input-width"
                label="SS Of AF PA Dashboard"
                prop="fileList.pa.soapd"
              >
                <el-upload
                  action="/api/finance/import/file"
                  :auto-upload="true"
                  :before-upload="beforeUpload"
                  :on-success="(resp, file) => handleUpload(resp, file, 'pasoapd')"
                  :on-remove="(file) => handleUploadRemove(file, 'pasoapd')"
                  :limit="10"
                  :file-list="pasoapdFileList"
                  ref="pasoapd"
                >
                  <el-button size="mini" type="primary">choose file</el-button>
                </el-upload>
              </el-form-item>
              <el-form-item
                class="approval-input-width"
                label="Raw data for the whole month"
                prop="fileList.pa.rdftwm"
              >
                <el-upload
                  action="/api/finance/import/file"
                  :auto-upload="true"
                  :on-success="(resp, file) => handleUpload(resp, file, 'pardftwm')"
                  :on-remove="(file) => handleUploadRemove(file, 'pardftwm')"
                  :limit="10"
                  :file-list="pardftwmFileList"
                  ref="pardftwm"
                >
                  <el-button size="mini" type="primary">choose file</el-button>
                </el-upload>
              </el-form-item>
              <el-form-item
                class="approval-input-width"
                label="Raw data during the month"
                prop="fileList.pa.rddtm"
              >
                <el-upload
                  action="/api/finance/import/file"
                  :auto-upload="true"
                  :on-success="(resp, file) => handleUpload(resp, file, 'parddtm')"
                  :on-remove="(file) => handleUploadRemove(file, 'parddtm')"
                  :limit="10"
                  :file-list="parddtmFileList"
                  ref="parddtm"
                >
                  <el-button size="mini" type="primary">choose file</el-button>
                </el-upload>
              </el-form-item>
            </div>
            <!-- reason over cap -->
            <div v-show="applyApprovalForm.reason === 'overcap'">
              <el-form-item
                class="approval-input-width"
                label="SS to proof the original cap"
                prop="fileList.overcap.stptoc"
              >
                <el-upload
                  action="/api/finance/import/file"
                  :auto-upload="true"
                  :on-success="(resp, file) => handleUpload(resp, file, 'stptoc')"
                  :on-remove="(file) => handleUploadRemove(file, 'stptoc')"
                  :limit="10"
                  :file-list="stptocFileList"
                  ref="stptoc"
                >
                  <el-button size="mini" type="primary">choose file</el-button>
                </el-upload>
              </el-form-item>
            </div>
            <!-- reason crm validtion-->
            <div v-show="applyApprovalForm.reason === 'crm_validation'">
              <el-form-item
                class="approval-input-width"
                label="SS of advertiser CRM/ email"
                prop="fileList.crm_validation.soace"
              >
                <el-upload
                  action="/api/finance/import/file"
                  :auto-upload="true"
                  :on-success="(resp, file) => handleUpload(resp, file, 'crmsoace')"
                  :on-remove="(file) => handleUploadRemove(file, 'crmsoace')"
                  :limit="10"
                  :file-list="crmsoaceFileList"
                  ref="crmsoace"
                >
                  <el-button size="mini" type="primary">choose file</el-button>
                </el-upload>
              </el-form-item>
              <el-form-item
                class="approval-input-width"
                label="Raw data for the whole month"
                prop="fileList.crm_validation.rdftwm"
              >
                <el-upload
                  action="/api/finance/import/file"
                  :auto-upload="true"
                  :on-success="(resp, file) => handleUpload(resp, file, 'crmrdftwm')"
                  :on-remove="(file) => handleUploadRemove(file, 'crmrdftwm')"
                  :limit="10"
                  :file-list="crmrdftwmFileList"
                  ref="crmrdftwm"
                >
                  <el-button size="mini" type="primary">choose file</el-button>
                </el-upload>
              </el-form-item>
              <el-form-item
                class="approval-input-width"
                label="Raw data during the month"
                prop="fileList.crm_validation.rddtm"
              >
                <el-upload
                  action="/api/finance/import/file"
                  :auto-upload="true"
                  :on-success="(resp, file) => handleUpload(resp, file, 'crmrddtm')"
                  :on-remove="(file) => handleUploadRemove(file, 'crmrddtm')"
                  :limit="10"
                  :file-list="crmrddtmFileList"
                  ref="crmrddtm"
                >
                  <el-button size="mini" type="primary">choose file</el-button>
                </el-upload>
              </el-form-item>
            </div>
            <!-- reason other-->
            <div v-show="applyApprovalForm.reason === 'other'">
              <el-form-item
                class="approval-input-width"
                label="Proof of deduction"
                prop="fileList.other.pod"
              >
                <el-upload
                  action="/api/finance/import/file"
                  :auto-upload="true"
                  :on-success="(resp, file) => handleUpload(resp, file, 'otherpod')"
                  :on-remove="(file) => handleUploadRemove(file, 'otherpod')"
                  :limit="10"
                  :file-list="otherpodFileList"
                  ref="otherpod"
                >
                  <el-button size="mini" type="primary">choose file</el-button>
                </el-upload>
              </el-form-item>
            </div>
          </el-col>
        </el-form>
      </el-row>
      <el-row>
        <el-col :xs="24" :lg="24" :md="24" :sm="24" align="center">
          <el-button
            @click="applyApprovalSubmit"
            type="success"
            size="small"
            style="margin-left: 10%"
            >Submit</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>

    <!--  提交状态审批 -->
    <el-dialog
      title="update status"
      :visible.sync="statusApproval.visible"
      :before-close="statusBeforeClose"
      width="60%"
      height="100%"
      top="3vh"
    >
      <el-row>
        <el-form
          :model="statusApprovalForm"
          size="mini"
          ref="statusApprovalForm"
          label-width="5rem"
          :rules="applyRules"
        >
          <el-col :xs="24" :lg="24" :md="24" :sm="24">
            <el-form-item class="approval-input-width" label="Operation" prop="operation">
              <el-input
                v-model="statusApprovalForm.operation"
                disabled
                placeholder="operation"
              ></el-input>
            </el-form-item>
            <el-form-item class="approval-input-width" label="BD" prop="business">
              <el-input
                v-model="statusApprovalForm.business"
                disabled
                placeholder="business"
              ></el-input>
            </el-form-item>
            <el-form-item class="approval-input-width" label="SourceName" prop="sourceName">
              <el-input
                v-model="statusApprovalForm.sourceName"
                disabled
                placeholder="sourceName"
              ></el-input>
            </el-form-item>
            <el-form-item class="approval-input-width" label="Prod" prop="prod">
              <el-input v-model="statusApprovalForm.prod" disabled placeholder="prod"></el-input>
            </el-form-item>
            <el-form-item class="approval-input-width" label="offer Type" prop="offerType">
              <el-input
                v-model="statusApprovalForm.offerType"
                disabled
                placeholder="offerType"
              ></el-input>
            </el-form-item>
            <el-form-item class="approval-input-width" label="AppName" prop="appName">
              <el-input v-model="statusApprovalForm.appName" placeholder="appName"></el-input>
            </el-form-item>
            <el-form-item class="approval-input-width" label="Update Status" prop="updateStatus">
              <el-select v-model="statusApprovalForm.updateStatus" style="width: auto">
                <el-option
                  v-for="item in statusUpdateList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item class="approval-input-width" label="Note 1" prop="note1">
              <el-input
                :autosize="{ minRows: 3, maxRows: 10 }"
                maxlength="255"
                show-word-limit
                type="textarea"
                v-model="statusApprovalForm.note1"
                placeholder=""
              ></el-input>
            </el-form-item>
            <el-form-item class="approval-input-width" label="Note 2" prop="note2">
              <el-input
                :autosize="{ minRows: 3, maxRows: 10 }"
                maxlength="255"
                show-word-limit
                type="textarea"
                v-model="statusApprovalForm.note2"
                placeholder=""
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <el-row>
        <el-col :xs="24" :lg="24" :md="24" :sm="24" align="center">
          <el-button
            @click="applyStatusApprovalSubmit"
            type="success"
            size="small"
            style="margin-left: 10%"
            :loading="statusApproval.submit"
            >Submit</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog
      title="Affiliate"
      :visible.sync="offerDetailVisible"
      @close="
        getList(1);
        getProgress();
      "
      top="3vh"
      width="80%"
    >
      <business-affiliate v-if="offerDetailVisible" ref="addOrUpdate" />
      <div align="center" class="pt10">
        <el-button
          @click="
            offerDetailVisible = false;
            getList(1);
            getProgress();
          "
          size="small"
          >cancel</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import bussinessProcess from '../controllers/finance/bussinessProcess';
  import GroupSelect from '@/components/GroupSelect/index.vue';
  export default {
    name: 'bussinessProcess',
    components: { GroupSelect },
    ...bussinessProcess,
  };
</script>

<style>
  .block {
    padding: 0 50px;
  }
  .table-row {
    height: 50px;
  }
  .approval-input-width {
    width: 10rem;
  }
  .el-progress {
    width: 35%;
  }
  .progress-label {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
  }
  .el-carousel__item:nth-child(2n) {
    background-color: #cfe0f5;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #cfe0f5;
  }
  .header-row {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }

  .row {
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 1;
  }
  .el-table .fixed-row {
    position: sticky;
    top: 0;
    z-index: 1;
  }
</style>
