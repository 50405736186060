<template>
  <div class="whiteList">
    <div class="mainBox">
      <el-card shadow="hover" class="mb5">
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="right" class="mr10">
            <el-button type="danger" size="small" @click="confirmRatio" :loading="loading.submitBtn"
              >confirm Ratio</el-button
            >
            <el-button type="danger" size="small" @click="confirmBatch" :loading="loading.submitBtn"
              >一键confirm</el-button
            >
          </el-col>
        </el-row>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width: 100%; overflow-x: auto"
          stripe
          border
          :data="list"
          tooltip-effect="dark"
          aria-setsize="mini"
          size="mini"
          class="mb10"
          max-height="800"
          @sort-change="sortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column label="Period" prop="financeCycle" align="center"></el-table-column>
          <el-table-column
            label="sourceName"
            prop="sourceName"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="BD Group" prop="businessType" align="center"></el-table-column>
          <el-table-column
            label="AffiliateName"
            prop="affiliateName"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="Prod"
            prop="prod"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="cpi" prop="cpi" align="center"></el-table-column>
          <el-table-column label="status" prop="status" align="center">
            <template slot-scope="scope"> {{ formatValue(scope.row.status) }} </template>
          </el-table-column>
          <el-table-column
            label="Events"
            prop="eventCount"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="Installs"
            prop="installs"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="Payout"
            prop="payout"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column label="Rejects" prop="rejects" align="center"></el-table-column>
          <el-table-column
            label="InstallRejectRate"
            prop="installRejectRate"
            align="center"
          ></el-table-column>
          <el-table-column label="Confirm %" prop="confirmRatio" align="center">
            <template slot-scope="scope">
              {{ (scope.row.confirmRatio * 100).toFixed(2) }}%
            </template>
          </el-table-column>
          <el-table-column label="Confirm Num" prop="confirm" align="center"></el-table-column>
          <el-table-column
            label="Note"
            prop="note"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="Confirm(BD)" prop="confirmBd" align="center"></el-table-column>
          <el-table-column label="Confirm(MMP)" prop="confirmAf" align="center"></el-table-column>
          <el-table-column label="BD Confirm%" prop="confirmAf" align="center">
            <template slot-scope="scope"> {{ computeRatio(scope.row) }}% </template>
          </el-table-column>
          <el-table-column label="Deduction report" prop="deductionFileName" align="center">
            <template slot-scope="scope">
              <span @click="openFile(scope.row)" class="cor337ab7">{{
                scope.row.deductionFileName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Attachments" prop="confirmAf" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="openAffDialog(scope.row)">view</el-button>
            </template>
          </el-table-column>
          <el-table-column label="Action" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                :disabled="scope.row.status === '2'"
                circle
                @click="openDialog(scope.row)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog :title="dialog.title" :visible.sync="dialog.visible" width="30%" append-to-body>
          <el-form
            :model="affiliateFinance"
            size="small"
            ref="affiliateFinanceForm"
            label-width="auto"
            :rules="rules"
          >
            <el-form-item label="confirm:" prop="confirm">
              <el-input
                v-model="affiliateFinance.confirm"
                disabled
                placeholder="Please enter confirm"
              ></el-input>
            </el-form-item>
            <el-form-item label="confirmRatio:" prop="confirmRatio">
              <el-input
                v-model.number="affiliateFinance.confirmRatio"
                placeholder="Please enter confirm %"
                @blur="computeConfirmNum()"
              >
                <template slot="append">%</template></el-input
              >
            </el-form-item>
            <el-form-item label="note:" prop="note">
              <el-input
                type="textarea"
                v-model="affiliateFinance.note"
                placeholder="Please enter note"
                maxlength="255"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="Deduction report:" prop="note">
              <el-upload
                class="upload-demo"
                action="/api/finance/import/file"
                :before-upload="(file) => beforeUpload(file)"
                :on-success="(resp, file) => handleUploadSuccess(resp, file)"
                :auto-upload="true"
                :limit="1"
                :file-list="fileList"
              >
                <el-button size="mini" type="primary">choose file</el-button>
              </el-upload>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialog.visible = false">取 消</el-button>
            <el-button type="primary" @click="update()">确 定</el-button>
          </span>
        </el-dialog>
        <el-dialog
          title="Attachments"
          :visible.sync="dialog.visibleAttachments"
          width="30%"
          append-to-body
        >
          <el-card shadow="hover">
            <span v-if="financeAttachments == null || financeAttachments.length === 0"
              >No Attachments</span
            >
            <span class="card-div-files" v-for="(item, index) in financeAttachments" :key="index">
              <div>{{ item.reasonKey }}</div>
              <div
                ><a :href="item.fileUrl" target="_blank">{{ item.fileName }}</a></div
              >
            </span>
          </el-card>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialog.visibleAttachments = false">取 消</el-button>
          </span>
        </el-dialog>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import financeAffiliate from '../controllers/finance/bussinessAffiliate';
  export default {
    name: 'financeAffiliate',
    ...financeAffiliate,
  };
</script>

<style scoped>
  .table-row {
    height: 50px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* 将 align-items 改为 flex-start，使按钮顶部对齐 */
    flex-wrap: wrap; /* 添加 flex-wrap 属性，使按钮换行显示 */
    width: 100%; /* 设置容器的宽度为100% */
  }
  .button-group .el-button {
    margin-bottom: 10px; /* 添加按钮之间的间距，可根据需要进行调整 */
  }
  .el-progress {
    width: 35%;
  }
  .progress-label {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
  }
  .card-div-files {
    display: block;
    border-radius: 3px;
    margin-top: 5px;
    border: 1px solid rgb(228, 224, 224);
    padding: 5px;
  }
  .card-div-files a {
    color: rgb(119, 173, 243);
  }
</style>
