import {
  fetchBDPage,
  edit,
  createLastMonth,
  editBatch,
  confirmRatioBatch,
} from '@/api/finance/financeAffiliate';
import { mapState, mapActions } from 'vuex';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import { selectProds } from 'api/product/overall';
import { getPidsList } from 'api/product/rankSearch';
import { approvalAttachment } from '@/api/finance/bussinessProcess';

export default {
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      filterForm: {
        page: 1,
        pageSize: 20,
        dateRange: [],
        showOffer: true,
      },
      total: 0,
      postData: {},
      list: [],
      loading: {
        list: false,
        submitBtn: false,
      },
      fileList: [],
      mmpRevenue: 0,
      affiliateFinance: {
        id: 0,
        confirm: 0,
        confirmRatio: 0,
        note: '',
        deductionFileUrl: '',
        deductionFileName: '',
      },
      dialog: {
        visible: false,
        submitBtn: false,
        visibleAttachments: false,
        title: '编辑',
        type: '',
      },
      multipleSelection: [],
      copyArr: [],
      rules: {
        confirmRatio: [
          { required: true, type: 'number', message: 'confirmRatio不能为空', trigger: 'blur' },
        ],
      },
      statusList: [
        {
          label: 'created',
          value: '0',
        },
        {
          label: 'confirm',
          value: '1',
        },
        {
          label: 'AM confirm',
          value: '2',
        },
      ],
      prodList: [],
      pidList: [],
      financeAttachments: [],
      businessTypes: [
        {
          label: 'overseas',
          value: 'overseas',
        },
        {
          label: 'domestic',
          value: 'domestic',
        },
      ],
      cpis: [
        {
          label: 'cpi',
          value: 'cpi',
        },
        {
          label: 'cpa',
          value: 'cpa',
        },
      ],
    };
  },
  methods: {
    // get Source
    ...mapActions('source', ['getSourceIdsList']),
    // get Affiliate
    ...mapActions('affiliate', ['getAffiliateList']),
    getDefaultTime() {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 2); // 设置日期为上上月
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const formattedMonth = month < 10 ? `0${month}` : month;
      let ddr = `${year}-${formattedMonth}`;
      this.filterForm.dateRange = [ddr, ddr]; // 设置默认起始日期
    },
    // 获取列表
    // 获取prods
    prodsList() {
      selectProds().then((res) => {
        this.prodList = res.result;
      });
    },
    init(row) {
      this.filterForm.sourceIdList = [row.sourceId];
      this.filterForm.prods = [row.prod];
      this.filterForm.financeCycle = row.financeCycle;
      this.filterForm.offerType = row.offerType;
      this.getList();
    },
    getList(page) {
      if (page) {
        this.filterForm.page = page;
      }
      let param = { ...this.filterForm };
      if (this.filterForm.sourceIdList) {
        param.sourceIdList = this.filterForm.sourceIdList.toString();
      }
      if (this.filterForm.affiliateIds) {
        param.affiliateIds = this.filterForm.affiliateIds.toString();
      }
      if (this.filterForm.prods) {
        param.prods = this.filterForm.prods.toString();
      }
      if (this.filterForm.pids) {
        param.pids = this.filterForm.pids.toString();
      }
      let rangeTime = this.filterForm.dateRange;
      if (Array.isArray(rangeTime) && rangeTime.length > 0) {
        param.startTime = this.filterForm.dateRange[0];
        param.endTime = this.filterForm.dateRange[1];
        // 删除多余的属性
        delete param.dateRange;
      }
      this.loading.list = true;
      fetchBDPage(param)
        .then((res) => {
          if (res.code === 200) {
            this.list = res.result;
            this.total = res.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.loading.list = false;
          this.$message.error(e.message);
        });
    },
    confirmBatch() {
      this.loading.submitBtn = true;
      editBatch({ ...this.filterForm })
        .then((response) => {
          if (response.success === true) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.getList();
            this.loading.submitBtn = false;
          } else {
            this.$message.error('Update Error:' + response.message);
            this.loading.submitBtn = false;
          }
        })
        .catch(() => {
          this.loading.submitBtn = false;
        });
    },
    confirmRatio() {
      this.$prompt('confirmRatio(%)', 'Batch Modify', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入 confirmRatio(%)',
      })
        .then(({ value }) => {
          this.loading.submitBtn = true;
          this.filterForm.confirmRatio = value;
          confirmRatioBatch({ ...this.filterForm })
            .then((resp) => {
              if (resp.success === true) {
                this.$message({
                  message: 'Update Success',
                  type: 'success',
                });
                this.loading.submitBtn = false;
                this.getList();
              } else {
                this.$message.error('Update  Error:' + resp.message);
                this.loading.submitBtn = false;
              }
            })
            .catch(() => {
              this.loading.submitBtn = false;
            });
        })
        .catch(() => {});
    },
    // 打开弹窗
    openDialog(row) {
      this.affiliateFinance.confirm = row.confirm;
      this.affiliateFinance.confirmRatio = Number(parseFloat(row.confirmRatio * 100).toFixed(2));
      this.affiliateFinance.note = row.note;
      this.affiliateFinance.prod = row.prod;
      this.affiliateFinance.sourceId = row.sourceId;
      this.affiliateFinance.affiliateId = row.affiliateId;
      this.affiliateFinance.financeCycle = row.financeCycle;
      this.affiliateFinance.payout = row.payout;
      this.affiliateFinance.deductionFileName = row.deductionFileName;
      this.affiliateFinance.deductionFileUrl = row.deductionFileUrl;
      this.fileList = [];
      if (row.deductionFileUrl) {
        this.fileList.push({ name: row.deductionFileName, url: row.deductionFileName });
      }
      this.dialog.visible = true;
    },

    // 打开弹窗
    openAffDialog(row) {
      let param = {
        prod: row.prod,
        sourceId: row.sourceId,
        financeCycle: row.financeCycle,
        status: 3,
      };
      approvalAttachment(param).then((res) => {
        this.financeAttachments = res.result;
      });
      this.dialog.visibleAttachments = true;
    },

    handleSizeChange(size) {
      this.filterForm.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.filterForm.page = currentPage;
      this.getList();
    },
    update() {
      this.$refs['affiliateFinanceForm'].validate((valid) => {
        if (valid) {
          var affForm = JSON.parse(JSON.stringify(this.affiliateFinance));
          affForm.confirmRatio = this.affiliateFinance.confirmRatio / 100;
          edit({ ...affForm }).then((response) => {
            if (response.success === true) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.getList();
              this.dialog.visible = false;
              this.affiliateFinance = {
                id: 0,
                confirm: 0,
                confirmRatio: 0,
                note: '',
              };
            } else {
              this.$message.error('Update Error:' + response.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.copyArr = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        this.copyArr.push(this.multipleSelection[i].id);
      }
      console.log(this.copyArr.toString());
    },
    openFile(row) {
      window.open(row.deductionFileUrl, '_blank');
    },
    // 批量新增上月数据
    insertBatch() {
      this.$confirm('此操作将批量生成上月数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          createLastMonth({}).then((res) => {
            if (res.success === true) {
              this.$message.success('新增成功！');
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    // 排序监听
    sortChange(column) {
      if (column.prop !== null && column.order !== null) {
        if (column.prop === 'eventCount') {
          this.filterForm.sorting = 'event_count';
        } else {
          this.filterForm.sorting = column.prop;
        }
        this.filterForm.sortType = column.order === 'ascending' ? 'asc' : 'desc';
      } else {
        this.filterForm.sorting = 'id';
        this.filterForm.sortType = 'desc';
      }
      this.getList();
    },
    formatValue(val) {
      if (val === '0') {
        return 'create';
      } else if (val === '1') {
        return 'BD confirm';
      } else if (val === '2') {
        return 'TO confirm';
      } else {
        return val;
      }
    },
    idDisableFun(val) {
      return !(val === '0');
    },
    computeConfirmNum() {
      let num = ((this.affiliateFinance.confirmRatio / 100) * this.affiliateFinance.payout).toFixed(
        3
      );
      this.affiliateFinance.confirm = num;
    },
    getPids() {
      getPidsList().then((res) => {
        this.pidList = res.result;
      });
    },
    computeRatio(row) {
      if (isNaN(row.confirmAf) || isNaN(row.confirmBd)) {
        return 0;
      }
      if (row.confirmAf === 0) {
        return 100;
      } else {
        return ((row.confirmBd / row.confirmAf) * 100).toFixed(3);
      }
    },
    // 上传之前
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 70;
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过70M!');
        return false;
      }
    },
    // 上传成功
    handleUploadSuccess(resp, file) {
      if (resp.code === 200) {
        this.affiliateFinance.deductionFileUrl = resp.result;
        this.affiliateFinance.deductionFileName = file.name;
        this.$message('上传成功！');
      } else {
        this.$message.error('上传失败:' + resp.message);
      }
    },
  },
  computed: {
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
  },
};
