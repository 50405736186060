import {
  fetchPage,
  edit,
  confirm,
  confirm2Adv,
  updateAwait,
  combine2Adv,
  updatePending,
  applyApprovalReasons,
  applyApproval,
  searchProgress,
  sourceAttachment,
  statusApproval,
  confirmBatch,
  updatePendingBatch,
} from 'api/finance/bussinessProcess';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import {
  getPermissionAllList,
  selectGroupBusiness,
  selectGroupAccount,
} from 'api/account/permission';
import { mapState, mapActions } from 'vuex';
import { filterObject } from 'utils/utils';
import qs from 'querystring';
import { selectProds, selectApps } from 'api/product/overall';
import BusinessAffiliate from '../../finance/bussinessAffiliate';

export default {
  components: {
    Pagination,
    GroupSelect,
    BusinessAffiliate,
  },
  data() {
    return {
      accounts: [],
      copyArr: [],
      filterForm: {
        page: 1,
        pageSize: 20,
        dateRange: [],
      },
      total: 0,
      postData: {},
      list: [],
      loading: {
        list: false,
        submitBtn: false,
      },
      offerDetailVisible: false,
      currentMonth: '',
      editData: {
        id: 0,
        appName: '',
        mmpRevenue: 0,
        confirmNum: 0,
        notes: '',
      },
      sumInfo: {
        mmpRevenue: 0,
        confirmNum: 0,
        mmpCount: 0,
        confirmCount: 0,
        confirmRatio: 0,
        countRatio: 0,
      },
      sumInfoMap: {},
      prodList: [],
      appList: [],
      dialog: {
        visible: false,
        title: '编辑',
        type: '',
      },
      pasoapdFileList: [],
      pardftwmFileList: [],
      parddtmFileList: [],
      stptocFileList: [],
      crmsoaceFileList: [],
      crmrdftwmFileList: [],
      crmrddtmFileList: [],
      otherpodFileList: [],
      bidAccountList: [],
      busineies: [],
      offerTypeList: [
        {
          label: 'rt',
          value: 'rt',
        },
        {
          label: 'ua',
          value: 'ua',
        },
      ],
      statusList: [
        {
          label: 'created',
          value: '0',
        },
        {
          label: 'confirm',
          value: '1',
        },
        {
          label: 'adv confirm',
          value: '2',
        },
        {
          label: 'pending',
          value: '3',
        },
        {
          label: 'await',
          value: '4',
        },
        {
          label: 'approval',
          value: '5',
        },
      ],
      statusUpdateList: [
        {
          label: 'created',
          value: '0',
        },
        {
          label: 'confirm',
          value: '1',
        },
        {
          label: 'pending',
          value: '3',
        },
        {
          label: 'await',
          value: '4',
        },
      ],
      businessTypes: [
        {
          label: 'overseas',
          value: 'overseas',
        },
        {
          label: 'domestic',
          value: 'domestic',
        },
      ],
      flag: false,
      rules: {
        mmpRevenue: [{ required: true, message: 'AF Revenue is null ', trigger: 'blur' }],
        confirmNum: [{ required: true, message: 'confirmNum is null', trigger: 'blur' }],
        appName: [{ min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur' }],
      },
      applyApproval: {
        visible: false,
        submit: false,
        reasonOptions: [
          { label: 'PA', value: 'pa' },
          { label: 'Over Cap', value: 'overcap' },
          { label: 'CRM Validation', value: 'crm_validation' },
          { label: 'Other', value: 'other' },
        ],
      },
      applyApprovalForm: {
        appName: '',
        offer: '',
        reason: 'pa',
        totalAmount: 0.0,
        deductionAmount: 0.0,
        coverAmount: 0.0,
        payableAmount: 0.0,
        deduction: 0.0,
        cover: 0.0,
        note1: '',
        note2: '',
        bussinessId: 0,
        fileList: {
          pa: {
            soapd: [],
            rdftwm: [],
            rddtm: [],
          },
          overcap: {
            stptoc: [],
          },
          crm_validation: {
            soace: [],
            rdftwm: [],
            rddtm: [],
          },
          other: {
            pod: [],
          },
        },
      },
      statusApproval: {
        visible: false,
        submit: false,
      },
      statusApprovalForm: {
        appName: '',
        operation: '',
        business: '',
        sourceId: 0,
        sourceName: '',
        prod: '',
        offerType: '',
        note1: '',
        note2: '',
        bussinessId: 0,
      },
      applyRules: {
        appName: [{ required: true }],
        deductionAmount: [{ required: true, type: 'number', trigger: 'blur' }],
        totalAmount: [{ required: true, type: 'number', trigger: 'blur' }],
        payableAmount: [{ required: true, type: 'number', trigger: 'blur' }],
        deduction: [{ required: true, type: 'number' }],
        note1: [{ required: true, min: 1, max: 255, message: '长度在 1 到 255 个字符' }],
        reason: [{ required: true }],
        updateStatus: [{ required: true }],
        'fileList.pa.soapd': [
          {
            required: true,
            message: 'SS Of AF PA Dashboard is required',
            validator: this.validatorUpload,
          },
        ],
        'fileList.pa.rdftwm': [
          {
            required: true,
            message: 'Raw data for the whole month is required',
            validator: this.validatorUpload,
          },
        ],
        'fileList.other.pod': [
          {
            required: true,
            message: 'Proof of deduction is required',
            validator: this.validatorUpload,
          },
        ],
        'fileList.crm_validation.rdftwm': [
          {
            required: true,
            message: 'Raw data for the whole month is required',
            validator: this.validatorUpload,
          },
        ],
        'fileList.crm_validation.soace': [
          {
            required: true,
            message: 'SS of advertiser CRM/ email is required',
            validator: this.validatorUpload,
          },
        ],
        'fileList.overcap.stptoc': [
          {
            required: true,
            message: 'SS to proof the original cap is required',
            validator: this.validatorUpload,
          },
        ],
      },
      applyApprovalFormReset: '',
      statusApprovalFormReset: '',
      applyApprovalReasons: {
        pa: {
          soapd: 'SS of AF PA dashboard',
          rdftwm: 'Raw data for the whole month',
          rddtm: 'Raw data during the month',
        },
        pa_reason: 'PA',
        overcap: {
          stptoc: 'SS to proof the orignal cap',
        },
        overcap_reason: 'Over cap',
        crm_validation: {
          soace: 'SS of advertiser CRM/ email',
          rdftwm: 'Raw data for the whole month',
          rddtm: 'Raw data during the month',
        },
        crm_validation_reason: 'CRM validation',
        other: {
          pod: 'Proof of deduction',
        },
        other_reason: 'Other',
      },
    };
  },
  mounted() {
    this.getAccounts();
    this.getSourceIdsList();
    this.getManagers();
    this.getBusiness();
    this.applyApprovalFormReset = JSON.stringify(this.applyApprovalForm);
    this.statusApprovalFormReset = JSON.stringify(this.statusApprovalForm);
    this.getApplyApprovalReason();
    this.getDefaultTime();
    this.getList();
    this.selectAccountRoles();
    this.getProgress();
    this.prodsList();
    this.selectAppsList();
  },
  computed: {
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
    ...mapState('user', {
      accountRoles: (state) => state.roles,
    }),
  },
  watch: {
    'filterForm.dateRange': function(newVal) {
      if (!newVal || newVal.length === 0) {
        this.flag = false;
      }
    },
  },
  methods: {
    // get Source
    ...mapActions('source', ['getSourceIdsList']),
    // get roles
    ...mapActions('user', ['selectAccountRoles']),
    getAccounts() {
      selectGroupAccount().then((res) => {
        this.accounts = res.result;
      });
    },
    // 获取所有的进度
    getProgress() {
      let param = { ...this.filterForm };
      let rangeTime = this.filterForm.dateRange;
      if (this.filterForm.sourceIdList) {
        param.sourceList = this.filterForm.sourceIdList.toString();
      }
      if (this.filterForm.businessList) {
        param.businessList = this.filterForm.businessList.toString();
      }
      if (this.filterForm.status) {
        param.statusList = this.filterForm.status.toString();
      }
      if (Array.isArray(rangeTime) && rangeTime.length > 0) {
        param.startTime = this.filterForm.dateRange[0];
        param.endTime = this.filterForm.dateRange[1];
        delete param.dateRange;
      }
      searchProgress(param)
        .then((res) => {
          if (res.code === 200) {
            this.sumInfoMap = res.result;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.loading.list = false;
          this.$message.error(e);
        });
    },
    // 获取列表
    getList(page) {
      if (page) {
        this.filterForm.page = page;
      }
      let param = { ...this.filterForm };
      let rangeTime = this.filterForm.dateRange;
      if (this.filterForm.sourceIdList) {
        param.sourceList = this.filterForm.sourceIdList.toString();
      }
      if (this.filterForm.businessList) {
        param.businessList = this.filterForm.businessList.toString();
      }
      if (this.filterForm.appNames) {
        param.appNameList = this.filterForm.appNames.toString();
      }
      if (this.filterForm.status) {
        param.statusList = this.filterForm.status.toString();
      }
      if (Array.isArray(rangeTime) && rangeTime.length > 0) {
        param.startTime = this.filterForm.dateRange[0];
        param.endTime = this.filterForm.dateRange[1];
        delete param.dateRange;
      }
      this.loading.list = true;
      fetchPage(param)
        .then((res) => {
          if (res.code === 200) {
            // this.sumInfo = res.result.pop();
            this.list = res.result;
            this.total = res.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.loading.list = false;
          this.$message.error(e);
        });
    },
    // 获取prods
    prodsList() {
      selectProds().then((res) => {
        this.prodList = res.result;
      });
    },
    selectAppsList() {
      selectApps().then((res) => {
        this.appList = res.result;
      });
    },
    detailAutoMixClick(row) {
      this.offerDetailVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(row);
      });
    },
    TableRowClassName(row) {
      console.log('打印row', row);
      // 这个根据自己需要固定的行或者修改样式的条件去判断
      if (!row.id) {
        return 'fixed-row'; //添加class样式
      }
    },
    handleParam() {},
    // 打开弹窗
    openDialog(row) {
      this.editData = { ...row };
      this.dialog.visible = true;
    },
    getDefaultTime() {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 2); // 设置日期为上上月
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const formattedMonth = month < 10 ? `0${month}` : month;
      let ddr = `${year}-${formattedMonth}`;
      this.filterForm.dateRange = [ddr, ddr]; // 设置默认起始日期
    },
    // 上传之前
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 70;
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过70M!');
        return false;
      }
    },
    formatOperationTime(row) {
      if (row.confirmStatus === '0') {
        return row.createTime;
      } else {
        return row.updateTime;
      }
    },
    handleSizeChange(size) {
      this.filterForm.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.filterForm.page = currentPage;
      this.getList();
    },
    editForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          edit({ ...this.editData }).then((response) => {
            if (response.success === true) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.getList();
              this.dialog.visible = false;
            } else {
              this.$message.error('Update Error:' + response.message);
            }
          });
        } else {
          console.log('error editForm!!');
          return false;
        }
      });
    },

    confirmBD(row) {
      this.$confirm('此操作将修改为 confirm?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let dif = row.diffrence;
          let businessType = row.businessType;
          let mmpRevenue = row.mmpRevenue;
          if ('domestic' === businessType) {
            if (mmpRevenue >= 100 && dif / mmpRevenue > 0.3) {
              this.$message.error(
                'Update  Error:' + 'MMP Revenue more than 100 and edution more than 30% '
              );
              return;
            }
          } else {
            if (dif > 2000) {
              this.$message.error('Update  Error:' + 'diffrence more than 2000');
              return;
            }
          }
          let obj = {};
          obj.id = row.id;
          obj.confirmStatus = '1';
          confirm(obj).then((response) => {
            if (response.success === true) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.getList();
            } else {
              this.$message.error('Update  Error:' + response.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    confirmADV() {
      this.$confirm('此操作将确认 ADV List?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.loading.submitBtn = true;
          let targetList = this.copyArr.slice();
          confirm2Adv(targetList).then((response) => {
            if (response.success === true) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.loading.submitBtn = false;
              this.getList();
            } else {
              this.loading.submitBtn = false;
              this.$message.error('Update  Error:' + response.message);
            }
          });
        })
        .catch(() => {
          this.loading.submitBtn = false;
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    confirmBatch() {
      if (this.copyArr.length === 0) {
        this.$message.error('Please select the item to be confirm');
        return;
      }
      this.$confirm('此操作将确认 confirm List?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let targetList = this.copyArr.slice();
          this.loading.submitBtn = true;
          confirmBatch(targetList)
            .then((response) => {
              if (response.success === true) {
                this.$message({
                  message: 'Update Success',
                  type: 'success',
                });
                this.loading.submitBtn = false;
                this.getList();
              } else {
                this.loading.submitBtn = false;
                this.$message.error('Update  Error:' + response.message);
              }
            })
            .catch((e) => {
              this.loading.submitBtn = false;
              this.$message.error(e);
            });
        })
        .catch(() => {
          this.loading.submitBtn = false;
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    pendingBatch() {
      if (this.copyArr.length === 0) {
        this.$message.error('Please select the item to be pending');
        return;
      }
      this.$confirm('此操作将确认 pending List?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.loading.submitBtn = true;
          let targetList = this.copyArr.slice();
          updatePendingBatch(targetList)
            .then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: 'Update Success',
                  type: 'success',
                });
                this.loading.submitBtn = false;
                this.getList();
              } else {
                this.loading.submitBtn = false;
                this.$message.error('Update  Error:' + response.message);
              }
            })
            .catch((e) => {
              this.loading.submitBtn = false;
              this.$message.error(e);
            });
        })
        .catch(() => {
          this.loading.submitBtn = false;
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },

    combineToAdv() {
      this.$confirm('此操作将 combine to ADV List?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let targetList = this.copyArr.slice();
          combine2Adv(targetList)
            .then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: 'Update Success',
                  type: 'success',
                });
                this.getList();
              } else {
                this.$message.error('Update  Error:' + response.message);
              }
            })
            .catch((e) => {
              this.$message.error(e);
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },

    update2Await(row) {
      this.$confirm('此操作将修改为await(待开票状态)', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let obj = {};
          obj.id = row.id;
          obj.confirmNum = row.confirmNum;
          updateAwait(obj)
            .then((response) => {
              if (response.success === true) {
                this.$message({
                  message: 'Update Success',
                  type: 'success',
                });
                this.getList();
              } else {
                this.$message.error('Update  Error:' + response.message);
              }
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: 'update error',
              });
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    update2Pending(row) {
      this.$confirm('此操作将修改为Pending', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let obj = {};
          obj.id = row.id;
          obj.confirmNum = row.confirmNum;
          updatePending(obj)
            .then((response) => {
              if (response.success === true) {
                this.$message({
                  message: 'Update Success',
                  type: 'success',
                });
                this.getList();
              } else {
                this.$message.error('Update  Error:' + response.message);
              }
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: 'update error',
              });
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    // 排序监听
    sortChange(column) {
      if (column.prop !== null && column.order !== null) {
        this.filterForm.sorting = column.prop;
        this.filterForm.sortType = column.order === 'ascending' ? 'asc' : 'desc';
      }
      this.getList();
    },
    formatValue(val) {
      if (val === '0') {
        return 'create';
      } else if (val === '1') {
        return 'confirm';
      } else if (val === '2') {
        return 'adv confirm';
      } else if (val === '3') {
        return 'pending';
      } else if (val === '4') {
        return 'await';
      } else if (val === '5') {
        return 'approval';
      } else {
        return val;
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.copyArr = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        let idFinance = this.multipleSelection[i].id;
        if (idFinance) {
          this.copyArr.push(this.multipleSelection[i].id);
        }
      }
    },

    isShowRangeTime() {
      let rangeTime = this.filterForm.dateRange;
      if (Array.isArray(rangeTime) && rangeTime.length > 0) {
        let startTime = this.filterForm.dateRange[0];
        let endTime = this.filterForm.dateRange[1];
        this.flag = startTime != endTime;
        console.log(this.flag);
      }
    },
    isShowApproval(row) {
      let dif = row.diffrence;
      let status = row.confirmStatus;
      let businessType = row.businessType;
      let mmpRevenue = row.mmpRevenue;
      if ('domestic' === businessType) {
        return status === '3' && mmpRevenue >= 100 && dif / mmpRevenue > 0.3;
      } else {
        return dif >= 2000 && status === '3';
      }
    },
    // 下载excel表格
    downloadFie() {
      this.$confirm('最多下载10万条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let param = { ...this.filterForm };
          let rangeTime = this.filterForm.dateRange;
          if (this.filterForm.sourceIdList) {
            param.sourceList = this.filterForm.sourceIdList.toString();
          }
          if (this.filterForm.businessList) {
            param.businessList = this.filterForm.businessList.toString();
          }
          if (this.filterForm.status) {
            param.statusList = this.filterForm.status.toString();
          }
          if (Array.isArray(rangeTime) && rangeTime.length > 0) {
            param.startTime = this.filterForm.dateRange[0];
            param.endTime = this.filterForm.dateRange[1];
            // 删除多余的属性
            delete param.dateRange;
          }

          param = filterObject(param);
          let strParam = qs.stringify(param);
          let url = '/api/finance/bussiness/download?' + strParam;
          console.log(url);
          window.open(url, '_blank');
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: error.message,
          });
        });
    },
    formatDedution(dedution) {
      if (typeof dedution === 'number' && !isNaN(dedution)) {
        return (dedution * 100).toFixed(3);
      } else {
        return 0;
      }
    },
    handleConfirmStatus(row) {
      let statusNum = row.confirmStatus;
      if (statusNum === '0') {
        return 'warning';
      }
      if (statusNum === '1') {
        return 'success';
      }
      if (statusNum === '2') {
        return 'danger';
      }
      if (statusNum === '4') {
        return 'warning';
      }
      return 'info';
    },
    idDisableConfirm(val) {
      if (this.accountRoles.indexOf('FinanceTeam') > -1 && val !== '2') {
        return false;
      }
      return val === '2' || val === '4';
    },
    isEdit(row) {
      // console.log('BD:' + this.accountRoles);
      if (row.confirmStatus === '2') {
        return true;
      }
      if (this.accountRoles.indexOf('FinanceTeam') > -1) {
        return false;
      } else if (row.confirmStatus == '1' && row.mmpRevenue == 0) {
        return false;
      } else {
        return row.confirmStatus != '0' && row.confirmStatus != '3';
      }
    },
    getManagers() {
      getPermissionAllList().then((res) => {
        let values = res.result.map((item) => item.username);
        this.bidAccountList = values;
      });
    },
    getBusiness() {
      selectGroupBusiness().then((res) => {
        // let values = res.result.map((item) => item.username);
        this.busineies = res.result;
      });
    },
    validatorUpload(rules, value, callback) {
      if (
        this.applyApprovalForm.reason == 'pa' &&
        (rules.field == 'fileList.pa.soapd' || rules.field == 'fileList.pa.rdftwm')
      ) {
        if (value.length == 0) {
          callback(new Error(rules.message));
          return;
        }
      }
      if (this.applyApprovalForm.reason == 'overcap' && rules.field == 'fileList.overcap.stptoc') {
        if (value.length == 0) {
          callback(new Error(rules.message));
          return;
        }
      }
      if (
        this.applyApprovalForm.reason == 'crm_validation' &&
        (rules.field == 'fileList.crm_validation.rdftwm' ||
          rules.field == 'fileList.crm_validation.soace')
      ) {
        if (value.length == 0) {
          callback(new Error(rules.message));
          return;
        }
      }
      if (this.applyApprovalForm.reason == 'other' && rules.field == 'fileList.other.pod') {
        if (value.length == 0) {
          callback(new Error(rules.message));
          return;
        }
      }
      callback();
    },
    toApplyApproval(row) {
      this.applyApprovalForm.appName = row.appName;
      this.applyApprovalForm.totalAmount = parseFloat(row.mmpRevenue);
      this.applyApprovalForm.deductionAmount = parseFloat(row.diffrence);
      this.applyApprovalForm.bussinessId = row.id;
      this.applyApprovalForm.sourceId = row.sourceId;
      this.applyAmountChange();
      let param = {
        prod: row.prod,
        sourceId: row.sourceId,
        financeCycle: row.financeCycle,
      };
      sourceAttachment(param).then((res) => {
        this.pasoapdFileList = [];
        this.pardftwmFileList = [];
        this.parddtmFileList = [];
        this.stptocFileList = [];
        this.crmsoaceFileList = [];
        this.crmrdftwmFileList = [];
        this.crmrddtmFileList = [];
        this.otherpodFileList = [];
        let list = res.result;
        if (list) {
          list.forEach((item) => {
            if (item.reason === 'PA') {
              if (item.reasonKey === 'SS of AF PA dashboard') {
                this.pasoapdFileList.push({ name: item.fileName, url: item.fileUrl });
                this.applyApprovalForm.fileList.pa.soapd.push({
                  fileName: item.fileName,
                  url: item.fileUrl,
                });
              } else if (item.reasonKey === 'Raw data for the whole month') {
                this.pardftwmFileList.push({ name: item.fileName, url: item.fileUrl });
                this.applyApprovalForm.fileList.pa.rdftwm.push({
                  fileName: item.fileName,
                  url: item.fileUrl,
                });
              } else if (item.reasonKey === 'Raw data during the month') {
                this.parddtmFileList.push({ name: item.fileName, url: item.fileUrl });
                this.applyApprovalForm.fileList.pa.rddtm.push({
                  fileName: item.fileName,
                  url: item.fileUrl,
                });
              }
            } else if (item.reason === 'Over cap') {
              if (item.reasonKey === 'SS to proof the orignal cap') {
                this.stptocFileList.push({ name: item.fileName, url: item.fileUrl });
                this.applyApprovalForm.fileList.overcap.stptoc.push({
                  fileName: item.fileName,
                  url: item.fileUrl,
                });
              }
            } else if (item.reason === 'CRM validation') {
              if (item.reasonKey === 'SS of advertiser CRM/ email') {
                this.crmsoaceFileList.push({ name: item.fileName, url: item.fileUrl });
                this.applyApprovalForm.fileList.crm_validation.soace.push({
                  fileName: item.fileName,
                  url: item.fileUrl,
                });
              } else if (item.reasonKey === 'Raw data for the whole month') {
                this.crmrdftwmFileList.push({ name: item.fileName, url: item.fileUrl });
                this.applyApprovalForm.fileList.crm_validation.rdftwm.push({
                  fileName: item.fileName,
                  url: item.fileUrl,
                });
              } else if (item.reasonKey === 'Raw data during the month') {
                this.crmrddtmFileList.push({ name: item.fileName, url: item.fileUrl });
                this.applyApprovalForm.fileList.crm_validation.rddtm.push({
                  fileName: item.fileName,
                  url: item.fileUrl,
                });
              }
            } else if (item.reason === 'Other') {
              if (item.reasonKey === 'Proof of deduction') {
                this.otherpodFileList.push({ name: item.fileName, url: item.fileUrl });
                this.applyApprovalForm.fileList.other.pod.push({
                  fileName: item.fileName,
                  url: item.fileUrl,
                });
              }
            }
          });
        }
      });
      this.applyApproval.visible = true;
      this.applyApproval.submit = false;
    },
    applyBeforeClose(done) {
      if (!this.applyApproval.submit) {
        this.$confirm('form data will lost before submit , go on ?')
          .then(() => {
            this.approvalFormReset();
            this.handleApplyReasonChange();
            done();
            this.approvalUploadReset();
          })
          .catch(() => {});
      } else {
        done();
      }
    },
    applyApprovalSubmit() {
      this.$refs['applyApprovalForm'].validate((valid) => {
        if (valid) {
          const formData = JSON.parse(JSON.stringify(this.applyApprovalForm));
          let data = {};
          data.appName = formData.appName;
          data.offer = formData.offer;
          data.totalAmount = formData.totalAmount;
          data.deduction = formData.deductionAmount;
          data.cover = formData.coverAmount;
          data.deductionRatio = formData.deduction;
          data.coverRatio = formData.cover;
          data.note = [formData.note1];
          data.bussinessId = formData.bussinessId;
          data.sourceId = formData.sourceId;
          if (formData.note2) {
            data.note.push(formData.note2);
          }
          data.financeAttachments = [];
          switch (formData.reason) {
            case 'pa':
              data.reason = this.applyApprovalReasons.pa_reason;
              for (let i in formData.fileList.pa.soapd) {
                const item = formData.fileList.pa.soapd[i];
                data.financeAttachments.push({
                  reason: data.reason,
                  reasonKey: this.applyApprovalReasons.pa.soapd,
                  fileName: item.fileName,
                  fileUrl: item.url,
                });
              }
              for (let i in formData.fileList.pa.rdftwm) {
                const item = formData.fileList.pa.rdftwm[i];
                data.financeAttachments.push({
                  reason: data.reason,
                  reasonKey: this.applyApprovalReasons.pa.rdftwm,
                  fileName: item.fileName,
                  fileUrl: item.url,
                });
              }
              for (let i in formData.fileList.pa.rddtm) {
                const item = formData.fileList.pa.rddtm[i];
                data.financeAttachments.push({
                  reason: data.reason,
                  reasonKey: this.applyApprovalReasons.pa.rddtm,
                  fileName: item.fileName,
                  fileUrl: item.url,
                });
              }
              break;
            case 'overcap':
              data.reason = this.applyApprovalReasons.overcap_reason;
              for (let i in formData.fileList.overcap.stptoc) {
                const item = formData.fileList.overcap.stptoc[i];
                data.financeAttachments.push({
                  reason: data.reason,
                  reasonKey: this.applyApprovalReasons.overcap.stptoc,
                  fileName: item.fileName,
                  fileUrl: item.url,
                });
              }
              break;
            case 'crm_validation':
              data.reason = this.applyApprovalReasons.crm_validation_reason;
              for (let i in formData.fileList.crm_validation.soace) {
                const item = formData.fileList.crm_validation.soace[i];
                data.financeAttachments.push({
                  reason: data.reason,
                  reasonKey: this.applyApprovalReasons.crm_validation.soace,
                  fileName: item.fileName,
                  fileUrl: item.url,
                });
              }
              for (let i in formData.fileList.crm_validation.rdftwm) {
                const item = formData.fileList.crm_validation.rdftwm[i];
                data.financeAttachments.push({
                  reason: data.reason,
                  reasonKey: this.applyApprovalReasons.crm_validation.rdftwm,
                  fileName: item.fileName,
                  fileUrl: item.url,
                });
              }
              for (let i in formData.fileList.crm_validation.rddtm) {
                const item = formData.fileList.crm_validation.rddtm[i];
                data.financeAttachments.push({
                  reason: data.reason,
                  reasonKey: this.applyApprovalReasons.crm_validation.rddtm,
                  fileName: item.fileName,
                  fileUrl: item.url,
                });
              }
              break;
            case 'other':
              data.reason = this.applyApprovalReasons.other_reason;
              for (let i in formData.fileList.other.pod) {
                const item = formData.fileList.other.pod[i];
                data.financeAttachments.push({
                  reason: data.reason,
                  reasonKey: this.applyApprovalReasons.other.pod,
                  fileName: item.fileName,
                  fileUrl: item.url,
                });
              }
              break;
          }
          applyApproval(data).then((resp) => {
            if (resp.code == 200) {
              this.approvalUploadReset();
              this.approvalFormReset();
              this.applyApproval.visible = false;
              this.applyApproval.submit = true;
              this.$message({
                message: 'Save Success',
                type: 'success',
              });
              this.getList();
            } else {
              this.$message.error(resp.message);
            }
          });
        } else {
          console.log(valid);
        }
      });
    },
    statusBeforeClose(done) {
      if (!this.applyApproval.submit) {
        this.$confirm('form data will lost before submit , go on ?')
          .then(() => {
            this.approvalStatusFormReset();
            this.handleStatusReasonChange();
            done();
          })
          .catch(() => {});
      } else {
        done();
      }
    },
    toStatusApproval(row) {
      this.statusApprovalForm.bussinessId = row.id;
      this.statusApprovalForm.sourceId = row.sourceId;
      this.statusApprovalForm.operation = row.operation;
      this.statusApprovalForm.business = row.business;
      this.statusApprovalForm.sourceName = row.sourceName;
      this.statusApprovalForm.prod = row.prod;
      this.statusApprovalForm.offerType = row.offerType;
      this.statusApprovalForm.appName = row.appName;
      this.statusApprovalForm.oldStatus = row.confirmStatus;
      this.statusApproval.visible = true;
      this.statusApproval.submit = false;
    },
    applyStatusApprovalSubmit() {
      this.statusApproval.submit = true;
      this.$refs['statusApprovalForm'].validate((valid) => {
        if (valid) {
          const formData = JSON.parse(JSON.stringify(this.statusApprovalForm));
          let data = {};
          data.bussinessId = formData.bussinessId;
          data.sourceId = formData.sourceId;
          data.operation = formData.operation;
          data.business = formData.business;
          data.sourceName = formData.sourceName;
          data.prod = formData.prod;
          data.offerType = formData.offerType;
          data.appName = formData.appName;
          data.oldStatus = formData.oldStatus;
          data.updateStatus = formData.updateStatus;
          data.note = [formData.note1];
          if (formData.note2) {
            data.note.push(formData.note2);
          }
          statusApproval(data)
            .then((resp) => {
              if (resp.code == 200) {
                this.approvalStatusFormReset();
                this.statusApproval.visible = false;
                this.statusApproval.submit = false;
                this.$message({
                  message: 'Save Success',
                  type: 'success',
                });
                this.getList();
              } else {
                this.statusApproval.submit = false;
                this.$message.error(resp.message);
              }
            })
            .catch((e) => {
              this.statusApproval.submit = false;
              this.$message.error(e);
            });
        } else {
          this.statusApproval.submit = false;
          console.log(valid);
        }
      });
    },
    handleStatusReasonChange() {
      this.$refs['statusApprovalForm'].clearValidate();
    },
    approvalStatusFormReset() {
      this.statusApprovalForm = JSON.parse(this.statusApprovalFormReset);
    },
    applyAmountChange() {
      let totalAmount = parseFloat(this.applyApprovalForm.totalAmount) - 0.0;
      let deductionAmount = parseFloat(this.applyApprovalForm.deductionAmount) - 0.0;
      let coverAmount = parseFloat(this.applyApprovalForm.coverAmount) - 0.0;
      if (!isNaN(totalAmount) && totalAmount !== 0) {
        this.applyApprovalForm.totalAmount = totalAmount;
      }
      if (!isNaN(deductionAmount) && deductionAmount !== 0) {
        this.applyApprovalForm.deductionAmount = deductionAmount;
      }
      if (!isNaN(coverAmount) && coverAmount !== 0) {
        this.applyApprovalForm.coverAmount = coverAmount;
      }
      Math.round();
      this.applyApprovalForm.payableAmount =
        Math.round((totalAmount - deductionAmount + coverAmount) * 1000) / 1000;
      if (totalAmount > 0) {
        this.applyApprovalForm.deduction =
          ((deductionAmount - coverAmount) / totalAmount).toFixed(2) * 100;
      }
      if (deductionAmount > 0) {
        this.applyApprovalForm.cover = (coverAmount / deductionAmount).toFixed(2) * 100;
      }
    },
    handleApplyReasonChange() {
      this.$refs['applyApprovalForm'].clearValidate();
    },
    approvalFormReset() {
      this.applyApprovalForm = JSON.parse(this.applyApprovalFormReset);
    },
    approvalUploadReset() {
      typeof this.$refs.pardftwm == 'undefined' ? '' : this.$refs.pardftwm.clearFiles();
      typeof this.$refs.pasoapd == 'undefined' ? '' : this.$refs.pasoapd.clearFiles();
      typeof this.$refs.parddtm == 'undefined' ? '' : this.$refs.parddtm.clearFiles();
      typeof this.$refs.stptoc == 'undefined' ? '' : this.$refs.stptoc.clearFiles();
      typeof this.$refs.crmsoace == 'undefined' ? '' : this.$refs.crmsoace.clearFiles();
      typeof this.$refs.crmrdftwm == 'undefined' ? '' : this.$refs.crmrdftwm.clearFiles();
      typeof this.$refs.crmrddtm == 'undefined' ? '' : this.$refs.crmrddtm.clearFiles();
      typeof this.$refs.otherpod == 'undefined' ? '' : this.$refs.otherpod.clearFiles();
    },
    getApplyApprovalReason() {
      applyApprovalReasons().then((resp) => {
        const data = resp.result;
        console.log('approval reasons', data);
      });
    },
    handleUpload(resp, file, type) {
      if (resp.code == 200) {
        let fileItem = { fileName: file.name, uid: file.uid, url: resp.result };
        switch (type) {
          case 'otherpod':
            this.applyApprovalForm.fileList.other.pod.push(fileItem);
            break;
          case 'crmrddtm':
            this.applyApprovalForm.fileList.crm_validation.rddtm.push(fileItem);
            break;
          case 'crmrdftwm':
            this.applyApprovalForm.fileList.crm_validation.rdftwm.push(fileItem);
            break;
          case 'crmsoace':
            this.applyApprovalForm.fileList.crm_validation.soace.push(fileItem);
            break;
          case 'stptoc':
            this.applyApprovalForm.fileList.overcap.stptoc.push(fileItem);
            break;
          case 'parddtm':
            this.applyApprovalForm.fileList.pa.rddtm.push(fileItem);
            break;
          case 'pardftwm':
            this.applyApprovalForm.fileList.pa.rdftwm.push(fileItem);
            break;
          case 'pasoapd':
            this.applyApprovalForm.fileList.pa.soapd.push(fileItem);
            break;
        }
      }
    },
    handleUploadRemove(file, type) {
      var list = [];
      switch (type) {
        case 'otherpod':
          list = this.applyApprovalForm.fileList.other.pod;
          break;
        case 'crmrddtm':
          list = this.applyApprovalForm.fileList.crm_validation.rddtm;
          break;
        case 'crmrdftwm':
          list = this.applyApprovalForm.fileList.crm_validation.rdftwm;
          break;
        case 'crmsoace':
          list = this.applyApprovalForm.fileList.crm_validation.soace;
          break;
        case 'stptoc':
          list = this.applyApprovalForm.fileList.overcap.stptoc;
          break;
        case 'parddtm':
          list = this.applyApprovalForm.fileList.pa.rddtm;
          break;
        case 'pardftwm':
          list = this.applyApprovalForm.fileList.pa.rdftwm;
          break;
        case 'pasoapd':
          list = this.applyApprovalForm.fileList.pa.soapd;
          break;
      }

      for (let i in list) {
        if (list[i].uid == file.uid) {
          list.splice(i, 1);
          break;
        }
      }
    },
  },
};
